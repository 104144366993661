import { Field, Form, Formik } from "formik"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../01_atoms/Button/Button"
import { Error } from "../../../01_atoms/Error/Error"
import { Input } from "../../../01_atoms/Input/Input"
import { Paragraph } from "../../../01_atoms/Paragraph/Paragraph"
import { Select } from "../../../01_atoms/Select/Select"
import Spacer from "../../../01_atoms/Spacer/Spacer"
import ReCAPTCHA from "react-google-recaptcha"

import * as styles from "./form.module.scss"

import initialValues from "./initialValues"
import validationSchema from "./validationSchema"
import { useRef } from "react"

const Contact = ({ fields, onSubmit = () => {}, formCompleted }) => {
  const { t } = useTranslation()
  const [thankyouMessage, setThankyouMessage] = useState("")
  const [disableDownloadField, setDisableDownloadField] = useState(false)

  const _reCaptchaRef = useRef()

  if (thankyouMessage && formCompleted) {
    return (
      <Paragraph>
        <span
          dangerouslySetInnerHTML={{
            __html: thankyouMessage,
          }}
        />
      </Paragraph>
    )
  }

  if (!thankyouMessage && !formCompleted) {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        onSubmit={values => {
          const msg = fields.findIndex(
            object => object["#webform_key"] === "feedback_message"
          )
          setThankyouMessage(fields[msg]["#markup"])
          onSubmit(values)
        }}
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          handleBlur,
          handleChange,
        }) => {
          const onBlur = e => {
            if (_reCaptchaRef.current && !values.recaptcha) {
              _reCaptchaRef.current.execute()
            }
            handleBlur(e)
          }

          const onSelectChange = e => {
            e.target.value === "US"
              ? setDisableDownloadField(true)
              : setDisableDownloadField(false)

            handleChange(e)
          }

          return (
            <Form className={styles.form}>
              <Field name="webform_id" id="webform_id" type="hidden" />
              {fields.map(
                (item, index) =>
                  item["#webform_key"] !== "feedback_message" && (
                    <div key={index} className={styles.item}>
                      {item["#type"] === "select" && (
                        <Field
                          onBlur={onBlur}
                          name={item["#webform_key"]}
                          id={item["#webform_id"]}
                          placeholder={item["#title"]}
                          label={item["#title"]}
                          required={item["#required"]}
                          items={item["#options"]}
                          component={Select}
                          disabledField={
                            disableDownloadField
                              ? "I want to request your brochure [PDF]"
                              : ""
                          }
                          onChange={onSelectChange}
                        />
                      )}

                      {item["#type"] === "textfield" && (
                        <Field
                          onBlur={onBlur}
                          name={item["#webform_key"]}
                          id={item["#webform_id"]}
                          placeholder={item["#title"]}
                          label={item["#title"]}
                          required={item["#required"]}
                          component={Input}
                        />
                      )}

                      {item["#type"] === "textarea" && (
                        <Field
                          onBlur={onBlur}
                          name={item["#webform_key"]}
                          type={item["#type"]}
                          id={item["#webform_id"]}
                          placeholder={item["#title"]}
                          label={item["#title"]}
                          required={item["#required"]}
                          component={Input}
                        />
                      )}

                      {item["#type"] === "email" && (
                        <Field
                          onBlur={onBlur}
                          name={item["#webform_key"]}
                          type={item["#type"]}
                          id={item["#webform_id"]}
                          placeholder={item["#title"]}
                          label={item["#title"]}
                          required={item["#required"]}
                          component={Input}
                        />
                      )}

                      {item["#type"] === "captcha" && (
                        <ReCAPTCHA
                          ref={_reCaptchaRef}
                          sitekey="6LfjTDkiAAAAAAVpe6AoAFKkckvK6DOMteFiSEwk"
                          onChange={value => {
                            setFieldValue("recaptcha", value)
                          }}
                          size="invisible"
                          badge="inline"
                        />
                      )}

                      {errors[item["#webform_key"]] &&
                        touched[item["#webform_key"]] && (
                          <Error>{errors[item["#webform_key"]]}</Error>
                        )}
                      <Spacer height={8} />
                    </div>
                  )
              )}

              <Button element="button" type="submit">
                {t("contact_form_submit")}
              </Button>
            </Form>
          )
        }}
      </Formik>
    )
  }
}

export default Contact
