const initialValues = {
  webform_id: "contact_us",
  name: "",
  country: "",
  e_mail_address: "",
  phone_number: "",
  company_industry: "",
  company_name: "",
  how_can_we_help: "",
  additional_details: "",
  i_want_to_contact_empwr_in: "",
  //recaptcha: "",
}

export default initialValues
