import { graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { useTranslation } from "react-i18next"
import { Heading } from "../components/01_atoms/Heading/Heading"
import Contact from "../components/02_molecules/forms/contact/Contact"
import ImageTextBlock from "../components/03_organisms/ImageTextBlock/ImageTextBlock"
import Layout from "../components/layout"
import Seo from "../components/seo"

import Api from "../api/Api"
import * as locationsStyle from "../components/02_molecules/Locations/locations.module.scss"
import HeroAnimation from "../components/03_organisms/animations/HeroAnimation/HeroAnimation"

const IndexPage = ({ data }) => {
  const pageData = data.allNodeContact.nodes[0]
  const form_id = pageData.field_form.drupal_internal__target_id
  const { language } = useI18next()
  const [fields, setFields] = useState([])
  const [formCompleted, setFormCompleted] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    Api.getFields(
      `${process.env.GATSBY_API_ROOT}/${language}/webform_rest/${form_id}/fields?_format=json`
    )
      .then(response => {
        setFields(Object.values(response))
      })
      .catch(error => {
        console.log(error)
      })
  }, [form_id, language])

  const submitForm = data => {
    Api.post(
      `${process.env.GATSBY_API_ROOT}/${language}/webform_rest/submit`,
      data
    )
      .then(response => {
        setFormCompleted(true)
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <Layout>
      <Seo
        title={pageData.field_meta_title}
        description={pageData.field_meta_description}
      />

      <Helmet
        bodyAttributes={{
          class: "contact-page",
        }}
      />

      {pageData && (
        <>
          <ImageTextBlock
            height="100"
            className="hero"
            image_position="right"
            animation={
              <HeroAnimation
                image={<img src="/img/heroes/hero_contact.png" alt="Contact" />}
                position="right"
                background_color="dusky_rose"
              />
            }
          >
            <Heading size="xl" spacing="m">
              {pageData.title}
            </Heading>
            <Contact
              fields={fields}
              form_id={form_id}
              formCompleted={formCompleted}
              onSubmit={data => submitForm(data)}
            />
          </ImageTextBlock>

          <ImageTextBlock
            height="75"
            className="locations"
            image_position="right"
            image_position_mobile="reverse"
            background_color="primary_blue"
            animation={
              <img
                alt="locations"
                style={{ objectFit: "contain", objectPosition: "right center" }}
                src="/img/locations.svg"
              />
            }
          >
            <Heading size="xl" spacing="l">
              {t("contact_locations_title")}
            </Heading>

            <ul className={locationsStyle.locations}>
              {pageData.relationships.field_components.map((item, index) => (
                <li key={index}>
                  <Heading size="l" spacing="m">
                    {item.field_title}
                  </Heading>
                  <ul>
                    {item.field_columns.map((column, index) => (
                      <li
                        key={index}
                        dangerouslySetInnerHTML={{ __html: column.processed }}
                      />
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </ImageTextBlock>
        </>
      )}
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNodeContact(
      filter: { langcode: { eq: $language }, status: { eq: true } }
    ) {
      nodes {
        title
        drupal_internal__nid
        internal {
          type
        }
        field_meta_description
        field_meta_title
        relationships {
          field_components {
            field_columns {
              processed
            }
            field_title
          }
        }
        field_form {
          status
          drupal_internal__target_id
        }
      }
    }
  }
`
