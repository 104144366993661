import cx from "classnames"
import React from "react"
import * as styles from "./styles/_Input.module.scss"

export const Input = ({ type = "text", label, field, required, ...props }) => {
  // define classes
  const classname = cx(styles.input)

  // render html
  return (
    <div className={classname}>
      {label && (
        <label className={styles.label}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </label>
      )}

      <div className={styles.wrapper}>
        {type === "textarea" ? (
          <textarea {...field} {...props}></textarea>
        ) : (
          <input {...field} {...props} type={type} />
        )}
      </div>
    </div>
  )
}
