import axios from "axios"

export const POST = (endpoint, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(endpoint, data, {
        headers: {
          "api-key":
            "a7c6ed90f8dc016e9c40f334064998fe2666061d02c6b36916d88e47a64598df",
        },
      })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        console.log(error)

        if (error.response) {
          reject({
            message: error.response.data.message,
            status: error.response.status,
            response: error.response,
          })
        } else if (error.request) {
          reject(error.message)
        } else {
          reject(error.message)
        }
      })
  })
}

export const GET = endpoint => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint, {
        headers: {
          "api-key":
            "a7c6ed90f8dc016e9c40f334064998fe2666061d02c6b36916d88e47a64598df",
        },
      })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        if (error.response) {
          reject({
            message: error.response.data.message,
            status: error.response.status,
            response: error.response,
          })
        } else if (error.request) {
          reject(error.message)
        } else {
          reject(error.message)
        }
      })
  })
}
