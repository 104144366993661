import cx from "classnames"
import React from "react"
import * as styles from "./select.module.scss"
import { useTranslation } from "react-i18next"

export const Select = ({
  label,
  items = [],
  field,
  form,
  required,
  disabledField,
  ...props
}) => {
  // define classes
  const classname = cx(styles.select)
  const { t } = useTranslation()

  const keys = Object.keys(items)

  // render html
  return (
    <div className={classname}>
      {label && (
        <label className={styles.label}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </label>
      )}

      <select className={styles.wrapper} {...field} {...props}>
        <option value="" disabled>
          {t("form_select_default")}
        </option>
        {keys.map((key, index) =>
          disabledField !== key ? (
            <option key={index} value={key}>
              {items[key]}
            </option>
          ) : null
        )}
      </select>
    </div>
  )
}
