import * as yup from "yup"

const validationSchema = t =>
  yup.object().shape({
    name: yup
      .string()
      .min(2, t("form_error_too_short"))
      .max(255, t("form_error_too_long"))
      .required(t("form_error_required")),
    country: yup.string().required(t("form_error_required")),
    e_mail_address: yup
      .string()
      .email()
      .required(t("form_error_required"))
      .min(2, t("form_error_too_short"))
      .max(255, t("form_error_too_long")),
    company_industry: yup.string().required(t("form_error_required")),
    company_name: yup.string().required(t("form_error_required")),
    how_can_we_help: yup.string().required(t("form_error_required")),
    i_want_to_contact_empwr_in: yup.string().required(t("form_error_required")),
    additional_details: yup
      .string()
      .min(2, t("form_error_too_short"))
      .max(255, t("form_error_too_long"))
      .required(t("form_error_required")),
    //recaptcha: yup.string().required(t("form_error_required")),
  })

export default validationSchema
