
import React from 'react';

import * as style from './error.module.scss';


export const Error = ({ children, ...props }) => {


    // render html
    return (
        <div className={style.error}>
            {children}
        </div>
    );

};